<template>
<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>search</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="search" fill="#DEDEDE" fill-rule="nonzero">
            <path d="M31.4710059,27.6474317 L25.2374874,21.3924053 C24.9688012,21.1227921 24.5590549,20.9812451 24.1560256,20.9812451 L23.0745639,20.9812451 C27.4138451,15.2721854 26.4600093,7.12312612 20.7705802,2.76887278 C15.0811511,-1.58538056 7.22879856,-0.76306027 2.76189141,4.94599945 C-1.70501574,10.6550592 -0.623554007,18.8108588 5.0658751,23.1583718 C9.80146839,26.8251114 16.3103902,26.8251114 21.0459835,23.1583718 L21.0459835,24.2503053 C21.0459835,24.6614654 21.1803266,25.0591449 21.4557299,25.3354985 L27.6892484,31.5905249 C28.2333379,32.1364917 29.1804565,32.1364917 29.8588891,31.5905249 L29.8588891,31.5905249 L31.6187833,29.8245584 C32.1494384,29.1370447 32.1494384,28.1799178 31.4710059,27.6474317 Z M13.0592879,20.9812451 C8.58566359,20.9812451 5.0658751,17.4493121 5.0658751,12.9602521 C5.0658751,8.47119217 8.58566359,4.93925912 13.0592879,4.93925912 C17.5329122,4.93925912 21.0527007,8.47119217 21.0527007,12.9602521 C21.0459835,17.3212458 17.3851348,20.9812451 13.0592879,20.9812451 L13.0592879,20.9812451 Z" id="searchicon"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
