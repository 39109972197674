<template>
<svg width="28px" height="32px" viewBox="0 0 28 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>user-ninja-solid</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="user-ninja-solid" fill="#000000" fill-rule="nonzero">
            <path d="M20.3375,18.075 L14,24.4125 L7.6625,18.075 C3.375,18.45625 0,22.0125 0,26.4 L0,29 C0,30.65625 1.34375,32 3,32 L25,32 C26.65625,32 28,30.65625 28,29 L28,26.4 C28,22.0125 24.625,18.45625 20.3375,18.075 Z M2,12 C3.70625,12 5.2375,11.28125 6.325,10.14375 C7.26875,13.5125 10.325,16 14,16 C18.41875,16 22,12.41875 22,8 C22,3.58125 18.41875,0 14,0 C10.85,0 8.15,1.8375 6.84375,4.4875 C5.75625,2.9875 4,2 2,2 C2,4.0875 3.06875,5.925 4.69375,7 C3.06875,8.075 2,9.9125 2,12 Z M11,6 L17,6 C18.10625,6 19,6.89375 19,8 L9,8 C9,6.89375 9.89375,6 11,6 Z" id="Shape"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
