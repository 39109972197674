<script>
import { Pie } from 'vue-chartjs'
import gradient from 'gradient-color'
import { mapGetters } from 'vuex'

export default {
  extends: Pie,
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontStyle: 'bold',
            fontSize: 13,
          },
        },
        title: {
          display: true,
          text: 'Annonces de vente en temps réel',
          position: 'top',
          fontSize: 20
        },
        tooltips: {
          enabled: true,
           titleFontFamily: "Moderat",
          titleFontSize: 15,
          bodyFontFamily: "Moderat",
          bodyFontStyle: "bold",
          bodyFontSize: 20,
          bodySpacing: 4,
          displayColors: false,
          callbacks: {
            label: (item, data) => {
              return data.labels[item.index] + ' ' + data.datasets[item.datasetIndex].data[item.index] + ' %'
            },
          },
        },
      },
    }
  },
  computed: {
    data() {
      return {
        labels: this.getDataLabelsFromES('adsCategoryBuy'),
        datasets: [
          {
            backgroundColor: this.gradient,
            data: this.getDataDistributionFromES('adsCategoryBuy'),
          },
        ],
      }
    },
    ...mapGetters(['getDataLabelsFromES', 'getDataDistributionFromES']), 
    gradient() {
      const colors = gradient(
        ['#40c9ff', '#e81cff'],
        this.getDataLabelsFromES('adsCategoryBuy').length,
      )
      return colors
    },   
  },
  watch: {
    data() {
      this.$data._chart.destroy()
      this.renderChart(this.data, this.options)
    },
  },
  mounted() {
    this.renderChart(this.data, this.options)
    
  },
}
</script>
