<template>
<svg width="28px" height="32px" viewBox="0 0 28 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>user-astronaut-solid</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="user-astronaut-solid" fill="#000000" fill-rule="nonzero">
            <path d="M4,14 L4.84375,14 C6.3875,17.53125 9.9,20 14,20 C18.1,20 21.6125,17.53125 23.15625,14 L24,14 C24.55,14 25,13.55 25,13 L25,7 C25,6.45 24.55,6 24,6 L23.15625,6 C21.6125,2.46875 18.1,0 14,0 C9.9,0 6.3875,2.46875 4.84375,6 L4,6 C3.45,6 3,6.45 3,7 L3,13 C3,13.55 3.45,14 4,14 Z M6.5,8.5 C6.5,7.11875 7.84375,6 9.5,6 L18.5,6 C20.15625,6 21.5,7.11875 21.5,8.5 L21.5,10 C21.5,13.3125 18.8125,16 15.5,16 L12.5,16 C9.1875,16 6.5,13.3125 6.5,10 L6.5,8.5 Z M11,13 L11.75,10.75 L14,10 L11.75,9.25 L11,7 L10.25,9.25 L8,10 L10.25,10.75 L11,13 Z M20.475,20.0875 C18.60625,21.29375 16.3875,22 14,22 C11.6125,22 9.39375,21.29375 7.525,20.0875 C3.30625,20.53125 0,24.0625 0,28.4 L0,29 C0,30.65625 1.34375,32 3,32 L8,32 L8,28 C8,26.89375 8.89375,26 10,26 L18,26 C19.10625,26 20,26.89375 20,28 L20,32 L25,32 C26.65625,32 28,30.65625 28,29 L28,28.4 C28,24.0625 24.69375,20.53125 20.475,20.0875 Z M17,28 C16.45,28 16,28.45 16,29 C16,29.55 16.45,30 17,30 C17.55,30 18,29.55 18,29 C18,28.45 17.55,28 17,28 Z M11,28 C10.45,28 10,28.45 10,29 L10,32 L12,32 L12,29 C12,28.45 11.55,28 11,28 Z" id="Shape"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
